import { RootStackNavigationProp } from '../../navigation/RootNavigation';
import { useResetPasswordState } from './reset-password-store';
import ResetPasswordService from '../../api/ResetPasswordService';
import { AxiosError } from 'axios';
import { ResetPasswordError } from '../../store/types';
import { useUserState } from '../../store/user-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageKeys } from '../../../enums/storage-keys';

export const sendResetLink = async (
  values: ResetPasswordForm,
  navigation: RootStackNavigationProp,
): Promise<void> => {
  useResetPasswordState.setState({ error: undefined, status: 'loading' });
  const userResetPassword = { ...values };
  try {
    const response =
      await ResetPasswordService.resetPassword(userResetPassword);
    await AsyncStorage.setItem(
      StorageKeys.ResetPasswordLinkId,
      response.resetPasswordLinkId!,
    );
    useResetPasswordState.setState({ status: 'success' });
    useUserState.setState({ data: userResetPassword as any });
    navigation.navigate('reset-link-sent');
  } catch (e) {
    if ((e as AxiosError).response?.status === 404) {
      navigation.navigate('reset-link-sent');
    }
    useResetPasswordState.setState({
      error: { message: 'Unable to send reset link. Please try again.' },
      status: 'error',
    });
  }
};

export const resetPassword = async (
  values: ResetPasswordForm,
  navigation: RootStackNavigationProp,
): Promise<void> => {
  const resetPasswordLinkId =
    (await AsyncStorage.getItem(StorageKeys.ResetPasswordLinkId)) || '';
  useResetPasswordState.setState({ error: undefined, status: 'loading' });
  //@ts-ignore
  const { password, email, confirmationCode, reset_password_link_id } = values;

  const userResetPassword: any = {
    confirmation_code: confirmationCode,
    password,
    email,
    reset_password_link_id,
    pharmacy_id: '09653b42-b122-4ebb-88bc-408eb59f7fd5', //FIXME: unhack this and the 'any' above
  };

  if (resetPasswordLinkId) {
    userResetPassword.reset_password_link_id = resetPasswordLinkId;
  }

  try {
    await ResetPasswordService.confirmPassword(userResetPassword);
    useResetPasswordState.setState({ status: 'success' });
    navigation.navigate('login');
  } catch (e) {
    useResetPasswordState.setState({
      error: { message: e as ResetPasswordError },
      status: 'error',
    });
  }
};

export interface ResetPasswordForm {
  email: string;
  password: string;
  passwordConfirm: string;
  confirmationCode: string;
}

export interface ResetPasswordResponse {
  resetPasswordLinkId: string;
}
